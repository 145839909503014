import React from "react"
import { Header, Icon, Breadcrumb } from "semantic-ui-react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const RefutationOverviewPage = (args) => {
  const misconceptions = args.data.misconceptions.nodes;
  const bgcolor = (i) => {
    return "hsl(0, 0%, "+ (100- (i + 1) % 2 * 5) + "%)";
  };
  return <Layout>
    <SEO title="Refutation Overview" />
    <div style={{ marginLeft: "10px", marginTop: "50px", marginBottom: "50px" }}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          Home
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section link as={Link} to="/overview">
          Overview
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Refutation Statements</Breadcrumb.Section>
      </Breadcrumb>

      <Header as="h1" dividing>
        <Icon name="lightbulb" />
        <Header.Content>
          Refutation Statements
          <Header.Subheader>
            {misconceptions.length} Misconceptions, {' '}
            {misconceptions.filter(mc => mc.frontmatter.shortDescription).length} with Short Descriptions, {' '}
            {misconceptions.filter(mc => mc.frontmatter.shortCorrection).length} with Short Corrections
          </Header.Subheader>
        </Header.Content>
      </Header>
      <table>
        <thead>
          <tr>
            <th>Misconception</th>
            <th>Language</th>
            <th>Status</th>
            <th>Short Description</th>
            <th>Short Correction</th>
          </tr>
        </thead>
        <tbody>
          {misconceptions.map((misconception, mi) => (
            <tr key={misconception.fields.pl+"/"+misconception.fields.name}>
              <td style={{backgroundColor: bgcolor(mi)}}>
                <Link to={`/misconceptions/${misconception.fields.pl}/${misconception.fields.name}/`}>
                  {misconception.fields.name}
                </Link>
              </td>
              <td style={{backgroundColor: bgcolor(mi)}}>
                {misconception.fields.pl}
              </td>
              <td style={{backgroundColor: bgcolor(mi)}}>
                { misconception.frontmatter.status === 'draft' 
                ? <span style={{color: '#e0e0e0'}}>draft</span>
                : misconception.frontmatter.status
                }
              </td>
              <td style={{backgroundColor: bgcolor(mi)}}>
                {misconception.frontmatter.shortDescription ? misconception.frontmatter.shortDescription : null}
              </td>
              <td style={{backgroundColor: bgcolor(mi)}}>
                {misconception.frontmatter.shortCorrection ? misconception.frontmatter.shortCorrection : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Layout>;
}

export default RefutationOverviewPage

export const pageQuery = graphql`
  query {
    misconceptions: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/src/data/misconceptions/**/index.md" }
      }
      sort: { fields: fields___name, order: ASC }
    ) {
      nodes {
        fields {
          slug
          name
          pl
        }
        frontmatter {
          status
          concepts
          shortDescription
          shortCorrection
        }
      }
    }
  }
`
